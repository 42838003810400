import React from 'react'
import styles from './LawyerOne.module.scss'
import image_2 from '../../image/Info/img_2.webp'

export const LawyerOne = () => {

    return (
            <div className={styles.container}>
                <div className={styles.box}>
                    <h2 className={styles.title}>Федосков</h2>
                    <h2 className={styles.title}>Владимир Петрович</h2>
                    <p className={styles.text}>Почетный адвокат Московской области</p>
                    <p className={styles.text}>Председатель МОКА «Надёжная защита»</p>
                    <p className={styles.text}>Адвокат с 1997 г. (юридический стаж с 1980 г.)</p>
                    <p className={styles.text}>Регистрационный №50/2975 в реестре адвокатов Московской области</p>
                </div>
                    <img className={styles.image} src={image_2} alt='адвокат'/>
            </div>
    )
}