import React, { useState, useCallback, useEffect} from 'react'
import { Route, Routes} from 'react-router-dom'
import app from './App.module.scss'
import { AppHeader } from '../AppHeader/AppHeader'
import { Main } from '../../pages/Main/Main'
import { ModalForm } from '../ModalForm/ModalForm'
import { Modal } from '../Modal/Modal'
import { UpButton } from '../UpButton/UpButton'
import { ButtonCommunication } from '../ButtonCommunication/ButtonCommunication'
import { MenuMobile } from '../MenuMobile/MenuMobile'
import { PageNotFound } from '../../pages/PageNotFound/PageNotFound'
import { PrivacyPolicy } from '../../pages/PrivacyPolicy/PrivacyPolicy'
import { Footer } from '../Footer/Footer'
import { Contacts } from '../../pages/Contacts/Contacts'
import {MyServices} from '../../pages/MyServices/MyServices'
import { ModalImage } from '../ModalImage/ModalImage'


export const App = () =>{

  const [isMenuMobile, setIsMenuMobile] = useState(false)
  const [isPopupFormOpen, setIsPopupFormOpen] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isPopupImageOpen, setIsPopupImageOpen] = useState(false)
  const [isIndex, setIsIndex] = useState(null) 

useEffect(() => {
  window.scrollTo(0, 0)
}, [])

  const handleCloseMenuMobile = useCallback(() => {
    setIsMenuMobile(false)
  }, []);

  const handleOpenMenuMobile = useCallback(() => {
    setIsMenuMobile(true)
  }, []);

  const handleFormOpen = useCallback(() => {
    setIsPopupFormOpen(true)
  }, []);

  const handleFormClose = useCallback(() => {
    setIsPopupFormOpen(false)
  }, []);

  const handleOpen = useCallback(() => {
    setIsPopupOpen(true)
  }, []);

  const handleClose = useCallback(() => {
    setIsPopupOpen(false)
  }, []);

  const handleImageClose = useCallback(() => {
    setIsPopupImageOpen(false)
  }, []);

  const handleImageOpen = useCallback((index) => {
      setIsIndex(index)
      setIsPopupImageOpen(true)
  }, [])

  return (
    <div className={app.page}>
    <AppHeader
        isOpen={handleOpenMenuMobile}
      />
      <Routes> 
        <Route  path='/'  element={
          <Main
            isOpen={handleFormOpen}
            isImageOpen={handleImageOpen}
          />
        }/>
      <Route  path='/uslugi'  element={
          <MyServices
            isOpen={handleFormOpen}
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/kontakty'  element={
          <Contacts
            isOpen={handleFormOpen}
            popupOpen={handleOpen}
          />
        }/>
        <Route path='*' element={
          <PageNotFound/>
                  }/>
        <Route  path='/privacy-policy'  element={
          <PrivacyPolicy/>
        }/>           
      </Routes>
      <Footer/>
      {isMenuMobile && <MenuMobile
        onClose={handleCloseMenuMobile}
      />}
      {isPopupFormOpen && <ModalForm
        onClose={handleFormClose}
        popupOpen={handleOpen}
      ></ModalForm>}
      {isPopupOpen && <Modal
        onClose={handleClose}
      ></Modal>}
      {isPopupImageOpen && 
      <ModalImage
        onClose={handleImageClose}
        index={isIndex}
      />}
      <UpButton/>
      <ButtonCommunication
        isOpen={handleFormOpen}
      />
    </div>
  )
}