import React, { useEffect} from 'react'
import {Helmet} from "react-helmet"
import { motion } from "framer-motion"
import styles from './MyServices.module.scss'
import { Callback } from '../../components/Callback/Callback'
import { BreadCrumbs } from '../../components/BreadCrumbs/BreadCrumbs'
import img_1 from '../../image/MyServices/img_1.webp'
import img_2 from '../../image/MyServices/img_2.webp'
import img_3 from '../../image/MyServices/img_3.webp'
import img_4 from '../../image/MyServices/img_4.webp'
import img_5 from '../../image/MyServices/img_5.webp'


const titleAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const subtitleAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}


export const MyServices = ({popupOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{once: true}} 
            >
            <Helmet>
                <title>Услуги адвокатов, юридические услуги Мытищи, Королев, Пушкино, Москва МОКА.</title>
                <meta name="description" content="Услуги адвокатов, юридические услуги Мытищи, Королев, Пушкино, Москва МОКА."></meta>
                <link rel="canonical" href="https://защита-адвокаты.рф/uslugi" />
                <meta property="og:title" content="Услуги адвокатов, юридические услуги Мытищи, Королев, Пушкино, Москва МОКА."/>
                <meta property="og:url" content="https://защита-адвокаты.рф/uslugi"/>
                <meta property="og:description" content="Услуги адвокатов, юридические услуги Мытищи, Королев, Пушкино, Москва МОКА."/>
            </Helmet>    
            <BreadCrumbs
                text={'Услуги'}
            />    
            <div className={styles.container}>
                <div className={styles.box}>
                    <motion.h1 className={styles.title} variants={titleAnimation}>Основные направления деятельности адвокатов <span>нашей коллегии</span></motion.h1>
                    <motion.p className={styles.text} variants={subtitleAnimation}>&#8195;Мы защищаем или представляем интересы граждан и юридических лиц по сложным уголовным делам, в том числе в отношении группы лиц включая представление интересов клиента сразу несколькими адвокатами.</motion.p>
                    <motion.p className={styles.text} variants={subtitleAnimation}>&#8195;Мы представляем интересы граждан и юридических лиц по сложным гражданским делам, рассматриваемым Мытищинским, Королёвским и Пушкинским городскими судами и мировыми судьями Мытищинского, Королёвского и Пушкинского судебных участков, а также судами г. Москвы, в том числе арбитражными.</motion.p>
                    <motion.p className={styles.text} variants={subtitleAnimation}>&#8195;Да, мы умеем выигрывать дела. Однако, выиграть дело не является для нас самоцелью. Мы не втягиваем своих клиентов в тяжбу, если ее итогом будет только положительное судебное решение. Наша цель – добиться ощутимого для клиента результата, а не только судебного подтверждения того, что его права нарушены. Мы экономим время и силы доверителя.</motion.p>
                </div>
                <ul className={styles.list}>
                    <li className={styles.item}>
                        <img className={styles.item_image} src={img_1} alt = 'адвокат'/>
                        <div className={styles.item_box}>
                            <h3 className={styles.item_title}>Гражданские дела</h3>
                            <p className={styles.item_text}>Среди споров мы можем выделить следущие направления:</p>
                            <p className={styles.item_text}>&#8195;<span>&#10004;</span> семейные споры;</p>
                            <p className={styles.item_text}>&#8195;<span>&#10004;</span> трудовые споры;</p>
                            <p className={styles.item_text}>&#8195;<span>&#10004;</span> наследственные споры;</p>
                            <p className={styles.item_text}>&#8195;<span>&#10004;</span> имущественные споры;</p>
                            <p className={styles.item_text}>&#8195;<span>&#10004;</span> потребительские споры;</p>
                            <p className={styles.item_text}>&#8195;<span>&#10004;</span> жилищные споры;</p>
                            <p className={styles.item_text}>&#8195;<span>&#10004;</span> земельные и другие споры.</p>
                        </div>
                    </li>
                    <li className={styles.item}>
                    <img className={styles.item_image} src={img_3} alt = 'адвокат'/>
                        <div className={styles.item_box}>
                            <h3 className={styles.item_title}>Уголовные дела</h3>
                            <p className={styles.item_text}>Наши адвокаты могут оказать достойную защиту и квалифицированное содействие по различным категориям уголовных дел:</p>
                            <p className={styles.item_text}>&#8195;<span>&#10004;</span> свидетелю;</p>
                            <p className={styles.item_text}>&#8195;<span>&#10004;</span> подозреваемому;</p>
                            <p className={styles.item_text}>&#8195;<span>&#10004;</span> обвиняемому;</p>
                            <p className={styles.item_text}>&#8195;<span>&#10004;</span> подсудимому;</p>
                            <p className={styles.item_text}>&#8195;<span>&#10004;</span> осужденному;</p>
                            <p className={styles.item_text}>&#8195;<span>&#10004;</span> потерпевшему;</p>
                            <p className={styles.item_text}>&#8195;<span>&#10004;</span> иным заинтересованным лицам.</p>
                        </div>
                    </li>
                    <li className={styles.item}>
                    <img className={styles.item_image} src={img_5} alt = 'адвокат'/>
                        <div className={styles.item_box}>
                            <h3 className={styles.item_title}>Арбитражные дела</h3>
                            <p className={styles.item_text}>Адвокаты Московской областной коллегии адвокатов "Надёжная защита" могут оказать следующие услуги:</p>
                            <p className={styles.item_text}>&#8195;<span>&#10004;</span> разрешение арбитражных споров;</p>
                            <p className={styles.item_text}>&#8195;<span>&#10004;</span> представительство интересов в суде.</p>
                        </div>
                    </li>
                    <li className={styles.item}>
                    <img className={styles.item_image} src={img_4} alt = 'адвокат'/>
                        <div className={styles.item_box}>
                            <h3 className={styles.item_title}>Услуги для бизнеса</h3>
                            <p className={styles.item_text}>Адвокаты Московской областной коллегии адвокатов "Надёжная защита" могут оказать следующие услуги:</p>
                            <p className={styles.item_text}>&#8195;<span>&#10004;</span> составление документации;</p>
                            <p className={styles.item_text}>&#8195;<span>&#10004;</span> сопровождение сделок и переговоров;</p>
                            <p className={styles.item_text}>&#8195;<span>&#10004;</span> урегулирование споров с оппонентами и государственными органами;</p>
                            <p className={styles.item_text}>&#8195;<span>&#10004;</span> представительство интересов в суде.</p>
                        </div>
                    </li>
                    <li className={styles.item}>
                    <img className={styles.item_image} src={img_2} alt = 'адвокат'/>
                        <div className={styles.item_box}>
                            <h3 className={styles.item_title}>Административные дела</h3>
                            <p className={styles.item_text}>К сфере административных дел мы можем выделить следующие направления:</p>
                            <p className={styles.item_text}>&#8195;<span>&#10004;</span> нарушения правил дорожного движения;</p>
                            <p className={styles.item_text}>&#8195;<span>&#10004;</span> нарушения норм общественного порядка.</p>
                        </div>
                    </li>
                </ul>
            </div>
            <Callback
                popupOpen={popupOpen}
            />
        </motion.section>
    )
}