import React from 'react'
import styles from './LawyerTwo.module.scss'
import image_3 from '../../image/Info/img_3.webp'

export const LawyerTwo = () => {

    return (
            <div className={styles.container}>
                <div className={styles.box_info}>
                    <img className={styles.image} src={image_3} alt='адвокат'/>
                    <div className={styles.box}>
                        <h2 className={styles.title}>Федосков</h2>
                        <h2 className={styles.title}>Владислав Владимирович</h2>
                        <p className={styles.text}>Член МОКА «Надёжная защита»</p>
                        <p className={styles.text}>Адвокат с 2010 г. (юридический стаж с 2005 г.)</p>
                        <p className={styles.text}>Pегистрационный №50/6848 в реестре адвокатов Московской области</p>
                    </div>
                </div>
                
            </div>
    )
}