import React, { useState} from 'react'
import { Link, useLocation } from 'react-router-dom'
import header from './header.module.scss'
import logo from '../../image/Header/logo.png'
import logos from '../../image/Header/logo_1.png'

export const AppHeader = ({isOpen}) => {

    const [visible, setVisible] = useState(false);
    const routes  = useLocation(); 

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 10){
            setVisible(true)
        } 
        else if (scrolled <= 10){
            setVisible(false)
        }
    };

    window.addEventListener('scroll', toggleVisible);


    return (
    <header className={`${header.header} ${visible && header.header_scroll}`}>
        <div className={header.box}>
                <Link className={header.logo_link} to='/'>
                    {routes.pathname === '/' ? (<img className={header.logo} src={logo} alt = 'логотип'/>) : (<img className={header.logo} src={logos} alt = 'логотип'/>)}
                </Link>
                <nav className={header.nav}>
                    <Link className={`${routes.pathname === '/' ? header.link : header.link_page} ${visible && header.link_scroll}`} to='/'>Главная</Link>
                    <Link className={`${routes.pathname === '/' ? header.link : header.link_page} ${visible && header.link_scroll}`} to='/uslugi'>Услуги</Link>
                    <Link className={`${routes.pathname === '/' ? header.link : header.link_page} ${visible && header.link_scroll}`} to='/kontakty'>Контакты</Link>
                </nav>
                <div className={header.box_phone}>
                    <a className={`${routes.pathname === '/' ? header.link_phone : header.link_phone_page} ${visible && header.link_phone_scroll}`} href="tel:+79637100494" target='_blank' rel='noopener noreferrer'>+7 (963) 710-04-94</a>
                    <a className={`${routes.pathname === '/' ? header.link_phone : header.link_phone_page} ${visible && header.link_phone_scroll}`} itemProp="telephone" href="tel:+79852205265" target='_blank' rel='noopener noreferrer'>+7 (985) 220-52-65</a>
                </div>
                <button className={header.button} type='button' onClick={isOpen} aria-label='Открыть меню'/>
            </div>
    </header>
    );
};