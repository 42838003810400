import React from 'react'
import styles from './Footer.module.scss'
import logo from '../../image/Header/logo_1.png'
import mail from '../../image/Footer/email-icon.svg'
import phone from '../../image/Footer/phone.svg'
import icon from '../../image/Footer/icon.svg'

export const Footer = () => {

    return (
        <footer className={styles.main}>
            <div className={styles.box_info}>
            <img className={styles.logo} src={logo} alt = 'логотип'/>
            <div className={styles.container}>
                <div className={styles.box}>
                    <div className={styles.box_address}>
                            <img className={styles.icon_address} src={icon} alt = 'icon'/>
                            <p className={styles.link_mail}>МО, г. Мытищи, Новомытищинский проспект, д. 11а, оф. 202</p>
                        </div>
                        <div className={styles.box_mail}>
                            <img className={styles.icon_mail} src={phone} alt = 'icon'/>
                            <a className={styles.link_mail} href="tel:+79637100494" target='_blank' rel='noopener noreferrer'>+7 (963) 710-04-94</a>
                        </div>
                        <div className={styles.box_mail}>
                            <img className={styles.icon_mail} src={phone} alt = 'icon'/>
                            <a className={styles.link_mail}  href="tel:+79852205265" target='_blank' rel='noopener noreferrer'>+7 (985) 220-52-65</a>
                        </div>
                        <div className={styles.box_mail}>
                            <img className={styles.icon_mail} src={mail} alt = 'icon'/>
                            <a className={styles.link_mail}  href="mailto:advokat-fedoskov@yandex.ru" target='_blank' rel='noopener noreferrer'>advokat-fedoskov@yandex.ru</a>
                        </div>
                    </div>
                    <div className={styles.box}>
                        <p className={styles.text}>Обращаем ваше внимание на то, что данный интернет-сайт носит исключительно информационный характер и ни при каких условиях не является публичной офертой, определяемой положениями Статьи 437 Гражданского кодекса Российской Федерации.</p>
                        <p className={styles.text}>Копирование материалов сайта запрещено.</p>
                        <p className={styles.text}>&#169; 2023 Все права защищены.</p>
                        <a className={styles.link_developer} target='_blank' rel='noopener noreferrer' href='https://tihonov-studio.ru/' aria-label='сайт тихонова дмитрия'>
                            <p className={styles.text}>Разработано<span> TD</span></p>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}