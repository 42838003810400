import React from 'react'
import styles from './Deadlines.module.scss'
import { motion } from "framer-motion"
import image_1 from '../../image/Deadlines/img_1.svg'
import image_2 from '../../image/Deadlines/img_2.svg'
import image_3 from '../../image/Deadlines/img_3.svg'
import image_4 from '../../image/Deadlines/img_4.svg'
import image_5 from '../../image/Deadlines/img_5.svg'
import image_6 from '../../image/Deadlines/img_6.svg'

export const Deadlines = ({isOpen}) => {

    const titleAnimation = {
        hidden: {
            y: 200,
            opacity: 0,
        },
    
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .5 }
        }
    }
    
    const boxAnimation = {
        hidden: {
            y: 500,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .9}
        } 
    }

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.15, once: true}}
        >
            <div className={styles.container}>
            <motion.h2 className={styles.title} variants={titleAnimation}>Как мы<span> работаем</span></motion.h2>
            <motion.ul className={styles.list} variants={boxAnimation}>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_1} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Консультация</h3>
                            <p className={styles.item_text}>Мы отвечаем на вопросы клиентов по телефону, даем советы и разъяснения, необходимые рекомендации, назначаем время и место очной встречи.</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_2} alt='встреча'/>
                            </div>
                            <h3 className={styles.item_title}>Очная встреча</h3>
                            <p className={styles.item_text}>Проведение очной встречи позволяет получить конфиденциальную информацию, которую неуместно сообщать посредством коммуникативных устройств.</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_3} alt='встреча'/>
                            </div>
                            <h3 className={styles.item_title}>Изучение дела</h3>
                            <p className={styles.item_text}>Для разработки стратегии необходимо тщательно изучить все имеющиеся материалы и детали текущего дела.</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_4} alt='обсуждение'/>
                            </div>
                            <h3 className={styles.item_title}>Обсуждение стратегии</h3>
                            <p className={styles.item_text}>После анализа дела мы можем обозначить возможные перспективы дела и главные векторы работы, необходимые для получения желаемого результата.</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_5} alt='договор'/>
                            </div>
                            <h3 className={styles.item_title}>Подписание соглашения</h3>
                            <p className={styles.item_text}>Если предложенные условия устраивают клиента, с ним заключается соглашение, в котором обозначены виды услуг и размер гонорара.</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_6} alt='соглашение'/>
                            </div>
                            <h3 className={styles.item_title}>Исполнение соглашения</h3>
                            <p className={styles.item_text}>Мы выполняем взятые обязательства, которые закреплены в соглашении, и ведем дело до полного исполнения условий соглашения.</p>
                        </li>
                    </motion.ul>
                    <button className={styles.button} type='button' onClick={isOpen}>консультация</button>
            </div>   
        </motion.section>
        
        
    )
}
