import React from 'react'
import styles from './InitialPage.module.scss'

export const InitialPage = ({isOpen}) => {
    return (
        <section
            className={styles.main}>
                <div className={styles.item}>
                    <div className={styles.overlay}>
                        <div className={styles.box}>
                            <h1 className={styles.title}>Московская областная коллегия адвокатов "Надёжная защита"</h1>
                            <p className={styles.subtitle}>Какой бы ни была ваша проблема— у нас есть стратегии, как ее разрешить</p>
                            <button className={styles.button} type='button' onClick={isOpen}>консультация</button>
                        </div>
                    </div>
                </div>    
        </section>
    )
}