import React from 'react'
import styles from './AboutUs.module.scss'

export const AboutUs = () => {

    return (
        <section className={styles.main}>
            <div className={styles.container}>
                    <div className={styles.box_info}>
                        <h2 className={styles.title}>О нашей <span> коллегии</span></h2>
                        <p className={styles.text}>&#8195;Московская областная коллегия адвокатов "Надёжная защита" создана опытными квалифицированными адвокатами Федосковым&#160;В.П. и Федосковым&#160;В.В. 5&#160;сентября 2011 года.</p>
                        <p className={styles.text}>&#8195;Московская областная коллегия адвокатов "Надёжная защита" это профессиональная команда из практикующих адвокатов, чей многолетний опыт позволяет предоставлять квалифицированную юридическую помощь и решать любые вопросы правового характера.</p>
                        <p className={styles.text}>&#8195;Мы защищаем или представляем интересы граждан и юридических лиц по сложным уголовным делам, в том числе в отношении группы лиц включая представление интересов сразу несколькими адвокатами.</p>
                        <p className={styles.text}>&#8195;Мы представляем интересы граждан и юридических лиц по сложным гражданским делам, рассматриваемым Мытищинским, Королёвским и Пушкинским городскими судами и мировыми судьями Мытищинского, Королёвского и Пушкинского судебных участков, а также судами г. Москвы, в том числе арбитражными.</p>
                        <p className={styles.text}>&#8195; Мы предоставляем консультации и справки по правовым вопросам, как в устной, так и в письменной форме в г. Мытищи (уголовное право, гражданское право, земельное право, административное право, семейное право, споры со страховыми компаниями, помощь при ДТП).</p>
                    </div>
            </div>
        </section>
        
        
    )
}