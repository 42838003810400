import React, {useEffect} from 'react'
import {Helmet} from "react-helmet"
import styles from './Main.module.scss'
import { InitialPage } from '../../components/InitialPage/InitialPage'
import { Advantages } from '../../components/Advantages/Advantages'
import {AboutUs} from '../../components/AboutUs/AboutUs'
import { LawyerOne } from '../../components/LawyerOne/LawyerOne'
import { LawyerTwo } from '../../components/LawyerTwo/LawyerTwo'
import {Deadlines } from '../../components/Deadlines/Deadlines'
import { SliderAdvantages } from '../../components/SliderAdvantages/SliderAdvantages'
import { SliderFreedom } from '../../components/SliderFreedom/SliderFreedom'
import {Portfolio} from '../../components/Portfolio/Portfolio' 

export const Main = ({isOpen, isImageOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section className={styles.main}>
            <Helmet>
                <title>Адвокат Мытищи, Королев, Пушкино. Бесплатная консультация! Федосков В.В.</title>
                <meta name="description" content="Услуги Адвоката Мытищи, Королев, Пушкино, Москва. Адвокаты предоставят правовую/юридическую помощь в любое время суток, семь дней в неделю. Бесплатные консультации по всем вопросам для жителей города Услуги адвоката Мытищи, Королев, Пушкино, Москва."></meta>
                <link rel="canonical" href="https://защита-адвокаты.рф/" />
                <meta property="og:title" content="Адвокат Мытищи, Королев, Пушкино. Бесплатная консультация! Федосков В.В."/>
                <meta property="og:url" content="https://защита-адвокаты.рф/"/>
                <meta property="og:description" content="Услуги Адвоката Мытищи, Королев, Пушкино, Москва. Адвокаты предоставят правовую/юридическую помощь в любое время суток, семь дней в неделю. Бесплатные консультации по всем вопросам для жителей города Мытищи, Королев, Пушкино, Москва."/>
            </Helmet>
            <InitialPage
                isOpen={isOpen}
            />
            <AboutUs/>
            <LawyerOne/>
            <LawyerTwo/>
            <Portfolio
                isImageOpen={isImageOpen}
            />
            <Advantages/>
            <Deadlines
                isOpen={isOpen}
            />
            <SliderFreedom/>
            <SliderAdvantages/>
        </section>
    )
}