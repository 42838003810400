import React from 'react'
import {Helmet} from "react-helmet"
import { Link} from 'react-router-dom'
import { useNavigate } from "react-router";
import styles from './PageNotFound.module.scss'

export const PageNotFound = () => {

    const navigate = useNavigate();
    
    const goBack = () => navigate(-1);

    return (
        <section className={styles.main}>
            <Helmet>
                <title>Такой страницы не существует</title>
                <meta name="description" content="Такой страницы не существует"></meta>
            </Helmet>  
            <div className={styles.box}>
                <h3 className={styles.subtitle}>Внимание, возникла ошибка</h3>
                <h2 className={styles.title}>404</h2>
                <p className={styles.text}>Страница не найдена</p>
                <Link className={styles.link} to='' onClick={goBack}>вернуться назад</Link>
            </div>
        </section>
        
    )
}