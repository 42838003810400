import React from 'react'
import { motion } from "framer-motion"
import styles from './Advantages.module.scss'
import image_1 from '../../image/Advantages/image_1.svg'
import image_2 from '../../image/Advantages/image_2.svg'
import image_3 from '../../image/Advantages/image_3.svg'
import image_4 from '../../image/Advantages/image_4.svg'
import image_5 from '../../image/Advantages/image_8.svg'
import image_6 from '../../image/Advantages/image_5.svg'

export const Advantages = () => {

    const titleAnimation = {
        hidden: {
            y: 200,
            opacity: 0,
        },
    
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .3 }
        }
    }
    
    const boxAnimation = {
        hidden: {
            y: 500,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .5}
        } 
    }

    return (
        <motion.section 
            className={styles.main} 
            id='Advantages'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}}
            >
                <motion.h2 className={styles.title} variants={titleAnimation}>Почему выбирают<span> нас?</span></motion.h2>
                <motion.ul  className={styles.list} variants={boxAnimation}>
                    <li className={styles.item}>
                        <div className={styles.box_icon}>
                            <img className={styles.icon} src={image_5} alt='телефон'/>
                        </div>
                        <div className={styles.box_info}>
                            <h3 className={styles.item_title}>честность</h3>
                            <p className={styles.item_text}>Клиент получает правдивую информацию о реальных перспективах дела, сложности задачи, сроках и стоимости юридических услуг.</p>
                        </div>
                    </li>
                    <li className={styles.item}>
                        <div className={styles.box_icon}>
                            <img className={styles.icon} src={image_1} alt='телефон'/>
                        </div>
                        <div className={styles.box_info}>
                            <h3 className={styles.item_title}>работа на результат</h3>
                            <p className={styles.item_text}>Задачей адвоката является достижение наилучшего результата для клиента. Именно в этом направлении будет вестись работа</p>
                        </div>
                    </li>
                    <li className={styles.item}>
                        <div className={styles.box_icon}>
                            <img className={styles.icon} src={image_2} alt='телефон'/>
                        </div>
                        <div className={styles.box_info}>
                            <h3 className={styles.item_title}>профессионализм</h3>
                            <p className={styles.item_text}>В нашей коллегии работают адвокаты с богатейшим опытом ведения дел разной степени сложности</p>
                        </div>
                    </li>
                    <li className={styles.item}>
                        <div className={styles.box_icon}>
                            <img className={styles.icon} src={image_6} alt='телефон'/>
                        </div>
                        <div className={styles.box_info}>
                            <h3 className={styles.item_title}>конфиденциальность</h3>
                            <p className={styles.item_text}>Данные клиента являюстя предметом адвокатской тайны и не могут быть разглашены или переданы третьим лицам ни при каких обстоятельствах</p>
                        </div>
                    </li>
                    <li className={styles.item}>
                        <div className={styles.box_icon}>
                            <img className={styles.icon} src={image_4} alt='телефон'/>
                        </div>
                        <div className={styles.box_info}>
                            <h3 className={styles.item_title}>круглосуточная поддержка</h3>
                            <p className={styles.item_text}>Коллегия осуществляет круглосуточную юридическую поддержку. При возникновении экстренной ситуации Вы можете рассчитывать на оперативную помощь</p>
                        </div>
                    </li>
                    <li className={styles.item}>
                        <div className={styles.box_icon}>
                            <img className={styles.icon} src={image_3} alt='телефон'/>
                        </div>
                        <div className={styles.box_info}>
                            <h3 className={styles.item_title}>работа по соглашению</h3>
                            <p className={styles.item_text}>Юридические услуги оказываются на основании заключаемого соглашения, в котором прописываются все нюансы работы и гонорар адвоката.</p>
                        </div>
                    </li>
                </motion.ul>
        </motion.section>
        
        
    )
}

