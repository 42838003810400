import React from 'react'
import { Link } from 'react-router-dom'
import styles from './MenuMobile.module.scss';
import logo from '../../image/Header/logo_1.png'

export const MenuMobile = ({onClose}) => {

    return (
        <div className={styles.main}>
            <div className={styles.box}>
                <img className={styles.logo} src={logo} alt = 'логотип'/>
                <button className={styles.button} onClick={onClose} aria-label='закрыть меню'/>
            </div>
            <nav className={styles.nav}>
                    <Link className={styles.menu_link} to='/' onClick={onClose}>Главная</Link>
                    <Link className={styles.menu_link} to='/uslugi' onClick={onClose}>Услуги</Link>
                    <Link className={styles.menu_link} to='/kontakty' onClick={onClose}>Контакты</Link>
            </nav>
            <div className={styles.box_phone}>
                    <a className={styles.link_phone} href="tel:+79637100494" target='_blank' rel='noopener noreferrer'>+7 (963) 710-04-94</a>
                    <a className={styles.link_phone}  href="tel:+79852205265" target='_blank' rel='noopener noreferrer'>+7 (985) 220-52-65</a>
            </div>
        </div>
    );
}
