import React from 'react'
import styles from './Portfolio.module.scss'
import { arrPortfolio } from '../../utils/data'
import { v4 as uuidv4 } from 'uuid'
import { CardImage } from '../CardImage/CardImage'


export const Portfolio = ({isImageOpen}) => {

    return (
        <section 
            className={styles.main}>
            <div className={styles.box}>
                <ul className={styles.list}>
                    {arrPortfolio.map((item, index) =>{
                        const keyUid = uuidv4()
                            return(
                                <CardImage
                                    key={keyUid}
                                    item={item}
                                    index={index}
                                    isImageOpen={isImageOpen} 
                                />
                            ) 
                    })}
                </ul>
            </div>
        </section>
    )
}